<template>
    <div>

        <!-- Media -->
        <b-row>
            <b-col cols="12" md="10">
                <b-media class="mb-2">
                    <template #aside>
                        <b-avatar ref="previewEl" :src="baseURL + UserUpdetedData.ProfileImagePath" size="90px"
                            rounded />
                    </template>
                    <h4 class="mb-1">
                        {{ UserUpdetedData.displayName }}
                    </h4>
                    <div class="d-flex flex-wrap">
                        <b-button variant="primary" @click="$refs.uploadImgInput.click()">
                            <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg"
                                accept="image/*" @on-success="successUpload" />
                            <span class="d-none d-sm-inline">{{ $t('Update') }}</span>
                            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                        </b-button>
                        <b-button variant="outline-secondary" class="ml-1"
                            @click="(UserUpdetedData.ProfileImagePath = null)">
                            <span class="d-none d-sm-inline">{{ $t('Remove') }}</span>
                            <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                        </b-button>
                    </div>
                </b-media>
            </b-col>
            <b-col cols="12" md="2">

                <div class="">
                    <h4 class="text-primary" @click="$router.push('/ChangePassword')">
                        <u style="text-decoration-line: underline;" @click="$router.push('/ChangePassword')">{{
        $t("EditPassword")
}}</u>
                    </h4>
                </div>
            </b-col>
        </b-row>


        <!-- User Info: Input Fields -->
        <b-form>
            <b-row>

                <!-- Field: Username -->
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('Name')" label-for="Name">
                        <b-form-input id="Name" v-model="UserUpdetedData.NameEN" />
                    </b-form-group>
                </b-col>

                <!-- Field: Full Name -->
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('Email')" label-for="Email">
                        <b-form-input id="Email" v-model="UserUpdetedData.Email" />
                    </b-form-group>

                </b-col>
                <!-- phonenumber -->
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('PhoneNumber')" label-for="PhoneNumber">
                        <VuePhoneNumberInput :placeholder="$t('PhoneNumber')"
                            :default-country-code="defaultPhoneData.countryCode" @update="acceptNumber"
                            v-model="UserUpdetedData.Phone" />

                    </b-form-group>
                </b-col>

                <!-- Field: PreferredLanguage -->
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('PreferredLanguage')" label-for="PreferredLanguage">
                        <v-select v-model="UserUpdetedData.PreferredLanguageID"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="languages"
                            :reduce="val => val.ID" label="Name" input-id="PreferredLanguage" />
                    </b-form-group>
                </b-col>

            </b-row>
        </b-form>




    </div>
</template>
  
<script>
import axios from "@/axios.js"
import { domain, mainSetting } from "@/gloabelConstant.js"
import moduleLanguage from "@/store/settings/language/moduleLanguage.js"
import { BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import vSelect from 'vue-select'

export default {
    components: {
        VuePhoneNumberInput,
        BFormRadioGroup,
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
    },
    data() {
        return {
            defaultPhoneData: {
                countryCode: ""
            },
            baseURL: domain,
            UploadRequestHeader: mainSetting.UploadRequestHeader,
            uploadUrl: domain + "api/Common/UploadFile",
            options: [],
            radios1: "luis",
        };
    },
    props: {
        UserUpdetedData: {
            type: Object,
            required: true,
        },
  },

    watch: {
        UserUpdetedData() {
            this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.UserUpdetedData.CountryID).IsoCode
        }
    },
    methods: {

        acceptNumber(value) {
            debugger
            this.UserUpdetedData.phonevalue = value.e164;
            this.UserUpdetedData.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
        },
        // acceptNumber(value) {
        //     debugger;
        //     if (value.countryCode != "US") {
        //         this.phonevalue = value.e164;
        //         this.defaultPhoneData.countryCode = value.countryCode;
        //     } else {
        //         this.phonevalue = value.e164;
        //         this.defaultPhoneData.countryCode;
        //     }

        // },

        changeMode() {
            debugger;
            this.UserUpdetedData.Next = true;
        },

        onSelectCountry() {
            this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.UserUpdetedData.CountryID).IsoCode
        },
        checkDailCod() {
            debugger;
            var code = this.countries.find((x) => x.ID === this.UserUpdetedData.CountryID)
                .DailCode;
            if (this.UserUpdetedData.Phone.length <= code.length) {
                this.UserUpdetedData.Phone = "+" + code;
            }
        },

        successUpload() {
            this.$vs.notify({
                color: "success",
                title: "Upload Success",
                text: "Lorem ipsum dolor sit amet, consectetur",
            });
        },
        updateCurrImg(input) {
            if (input.target.files && input.target.files[0]) {
                let data = new FormData();
                data.append("file", input.target.files[0]);

                axios.post(this.uploadUrl, data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((response) => {
                    this.UserUpdetedData.ProfileImagePath = response.data;
                });
            }
        },
    },
    computed: {
        languages() {
            return this.$store.state.LanguageList.languages;
        },

        countries() {
            return this.$store.state.CountryList.countries;
        },
    },
    created() {
        if (!moduleLanguage.isRegistered) {
            this.$store.registerModule("LanguageList", moduleLanguage);
            moduleLanguage.isRegistered = true;
        }
        this.$store.dispatch("LanguageList/GetAllLanguages");
        this.$store.dispatch("CountryList/GetAllCountries");

        this.onSelectCountry();

    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
  