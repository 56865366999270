<template>
    <b-row>

        <b-col>
            <div class="w-full ml-10">
                <h1>
                    <p>{{ $t("YourNotification") }}</p>
                </h1>
            </div>
            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("onlineappointmentNotification") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsOnlineAppointmentNotification" switch inline>

                    </b-form-checkbox>
                    <!-- <vs-switch class="round" v-model="UserUpdetedData.Model.IsOnlineAppointmentNotification" /> -->

                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("SergeryNotify") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsSurgeryDateNotification" switch inline>
                    </b-form-checkbox>
                    <!-- <vs-switch class="round" v-model="UserUpdetedData.Model.IsSurgeryDateNotification" /> -->
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("OnlineReleas") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsOnlineAppointmentpaymentIsDue" switch inline>
                    </b-form-checkbox>
                    <!-- <vs-switch class="round" v-model="UserUpdetedData.Model.IsOnlineAppointmentpaymentIsDue" /> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("SergeryPayment") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsSurgeryPaymentDue" switch inline>
                    </b-form-checkbox>
                    <!-- <vs-switch class="round" v-model="UserUpdetedData.Model.IsSurgeryPaymentDue" /> -->
                </b-col>
            </b-row>

        </b-col>

        <b-col>
            <div class="w-full ml-10">
                <h1>
                    <p>{{ $t("permissions") }}</p>
                </h1>
            </div>
            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("Promotion") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsReceiveOffersAndPromotions" switch inline>
                    </b-form-checkbox>
                    <!-- <vs-switch class="round" v-model="UserUpdetedData.Model.IsReceiveOffersAndPromotions" /> -->
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("NewsMonthely") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsReceiveMonthlyNewsletter" switch inline>
                    </b-form-checkbox>
                    <!-- <vs-switch class="round" v-model="UserUpdetedData.Model.IsReceiveMonthlyNewsletter" /> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("InfoPackage") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsReceiveInformationPackages" switch inline>
                    </b-form-checkbox>
                    <!-- <vs-switch class="round" v-model="UserUpdetedData.Model.IsReceiveInformationPackages" /> -->
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="8">
                    <h4>
                        {{ $t("InfoHospital") }}
                    </h4>
                </b-col>
                <b-col cols="12" md="4">
                    <b-form-checkbox v-model="UserUpdetedData.Model.IsReceiveInformationRecentlyAddedHospitalsAndDoctors" switch inline>
                    </b-form-checkbox>
                    <!-- <vs-switch class="round"
                        v-model="UserUpdetedData.Model.IsReceiveInformationRecentlyAddedHospitalsAndDoctors" /> -->
                </b-col>
            </b-row>


        </b-col>
    </b-row>

</template>

<script>
import axios from "@/axios.js"
import { domain, mainSetting } from "@/gloabelConstant.js"
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import modulePatient from "@/store/Patient/modulePatient.js";

import {
    BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox,
    BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable
} from 'bootstrap-vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import vSelect from 'vue-select'

export default {
    components: {
        VuePhoneNumberInput,
        BFormRadioGroup,
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
    },

    data() {
        return {
            defaultPhoneData: {
                countryCode: ""
            },
            baseURL: domain,
            UploadRequestHeader: mainSetting.UploadRequestHeader,
            uploadUrl: domain + "api/Common/UploadFile",
            options: [],
            radios1: "luis",
            //Model: {},
            UserData:{},
        };
    },
    props: {
        UserUpdetedData: {
            type: Object,
            required: true,
        },
        // Model:{
        //    type:Object,
        //    required: true,

        //  }
    },

    watch: {


    },
    methods: {


    },
    created() {
        this.EnglishLan =
            localStorage.getItem("SaveLang") == "en" ||
                localStorage.getItem("SaveLang") == null
                ? true
                : false;

        if (!moduleLanguage.isRegistered) {
            this.$store.registerModule("LanguageList", moduleLanguage);
            moduleLanguage.isRegistered = true;
        }

        this.$store.dispatch("LanguageList/GetAllLanguages");
        if (!modulePatient.isRegistered) {
            this.$store.registerModule("patientList", modulePatient);
            modulePatient.isRegistered = true;
        }

        this.UserData = this.$store.state.AppActiveUser.Patient;
        this.UserUpdetedData.NameEN = this.UserData.NameEN;
        this.UserUpdetedData.Phone = this.UserData.Phone;
        this.UserUpdetedData.Email = this.UserData.Email;
        this.UserUpdetedData.ProfileImagePath = this.UserData.ProfileImagePath;
        this.UserUpdetedData.PreferredLanguageID =
            this.UserData.PreferredLanguageID;

        debugger;
        this.$store
            .dispatch("patientList/GetUserNotificationSettings")
            .then((res) => {
                debugger;
                if (res.data.Data) this.Model = res.data.Data;
            });
    },

}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.textfont {
    font-family: "futuraMedium";
}
</style>
  