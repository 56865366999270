<template>
  <component :is="UserData === undefined ? 'div' : 'b-card'">
     <!-- Alert: No item found -->
     <!-- <b-alert variant="danger" :show="UserUpdetedData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>   -->

    <b-tabs  pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{$t('MainData')}}</span>
        </template>
         <MainInfo  :UserUpdetedData="UserUpdetedData" class="mt-2 pt-75" /> 
      </b-tab>

      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('NotificationAndPermissions')}}</span>
        </template>
        <NotificationAndPermissions :UserUpdetedData="UserUpdetedData" class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
       <!-- Action Buttons -->
     <div class="mt-2">
       <b-button
      variant="primary"
      class="mb- mb-sm-0 mr-0 mr-sm-1"
      @click="SaveSetting()"
    >
      {{$t('SaveChanges')}}
    </b-button>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </div>
  </component>
</template>

<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import VuePhoneNumberInput from 'vue-phone-number-input';
import { BCardText, BTableLite,BBadge, BButton, BCard, BCardBody, BCol, BFormSpinbutton, BImg, BLink, BRow, BTab, BTabs } from 'bootstrap-vue';
import imageLazy from "@/components/image_lazy.vue";
import MainInfo from '@/views/Patient/MainInfo';
import NotificationAndPermissions from '@/views/Patient/NotificationAndPermissions';

export default {
  components: {
    VuePhoneNumberInput,
    BBadge, BButton, BCard, BCardBody, BCol, BFormSpinbutton, BImg, BLink, BRow,
    BCardText, BTableLite,
     BTab, BTabs,
    imageLazy,
    MainInfo,
    NotificationAndPermissions
  },
  data() {
    return {
      defaultPhoneData: {
        countryCode: ""
      },
      phonevalue: '',
      EnglishLan: false,
      baseURL: domain,
      UserData: {
        ProfileImagePath: "",
      },
      UserUpdetedData: {
        ProfileImagePath: "",
        PreferredLanguageID: 0,
        Email: "",
        Phone: "",
        NameEN: "",
        preferredcountries: ""
        ,Model:{},

      },
      //Model: {},
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      options: [],
      radios1: "luis",
      date: null,
    };
  },
  props: {},
  computed: {
    languages() {
      return this.$store.state.LanguageList.languages;
    },
  },
  // watch: {
  //   Model () {
  //     debugger;
  //     this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.Model.CountryID).IsoCode
  //   }
  // },
  methods: {
    back() {
      this.$router.go(-1);
    },
    acceptNumber(value) {
      debugger;
      if (value.countryCode != "US") {
        this.phonevalue = value.e164;
        this.defaultPhoneData.countryCode = value.countryCode;
      } else {
        this.phonevalue = value.e164;
        this.defaultPhoneData.countryCode;
      }

      //  this.Model.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
    },

    SaveSetting() {
      debugger;
      this.Model.Phone = this.phonevalue;
      this.$vs.loading();
      if (this.Model.ID) {
        this.$store
          .dispatch("patientList/UpdateUserNotificationSetting", this.Model)
          .then((res) => {
            debugger;
            window.showSuccess(res.data.Message);
            this.$vs.loading.close();
            this.saveUserUpdetedData();
          })
          .catch((err) => {
            window.showError(err.response.data.message);
            this.$vs.loading.close();
          });
      } else {
        this.$store
          .dispatch("patientList/AddUserNotificationSetting", this.Model)
          .then((res) => {
            window.showSuccess(res.data.Message);

            this.$vs.loading.close();

            this.saveUserUpdetedData();
          })
          .catch((err) => {
            window.showError(err.response.data.message);
            this.$vs.loading.close();
          });
      }
    },
    saveUserUpdetedData() {
      this.$vs.loading();
      this.UserData.Phone = this.phonevalue;
      this.UserData.NameEN = this.UserUpdetedData.NameEN;
      //  this.UserData.Phone = this.UserUpdetedData.Phone;
      this.UserData.Email = this.UserUpdetedData.Email;
      this.UserData.ProfileImagePath = this.UserUpdetedData.ProfileImagePath;
      this.UserData.PreferredLanguageID =
        this.UserUpdetedData.PreferredLanguageID;

      this.$store
        .dispatch("patientList/UpdatePatient", this.UserData)
        .then((res) => {
          console.error(res);
          this.$vs.loading.close();
          window.showAddSuccess();
          this.$store.state.AppActiveUser.Patient = this.UserData;
          localStorage.setItem(
            "userInfo",
            JSON.stringify(this.$store.state.AppActiveUser)
          );

          this.back();

          // this.initUserUpdetedDataValues();
        })
        .catch((err) => {
          window.showError(err.response.data.message);
          this.$vs.loading.close();
          console.error(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          this.UserUpdetedData.ProfileImagePath = response.data;
        });
      }
    },
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
        localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }

    this.$store.dispatch("LanguageList/GetAllLanguages");

    this.$store.dispatch("CountryList/GetAllCountries");

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    this.UserData = this.$store.state.AppActiveUser.Patient;
    this.UserUpdetedData.NameEN = this.UserData.NameEN;
    this.UserUpdetedData.Phone = this.UserData.Phone;
    this.UserUpdetedData.Email = this.UserData.Email;
    this.UserUpdetedData.ProfileImagePath = this.UserData.ProfileImagePath;
    this.UserUpdetedData.PreferredLanguageID =
      this.UserData.PreferredLanguageID;
      var vm =this;
    debugger;
    this.$store
      .dispatch("patientList/GetUserNotificationSettings")
      .then((res) => {
        debugger;
        if (res.data.Data) vm.UserUpdetedData.Model = res.data.Data;
      });
  },
};
</script>
<style>
.textfont {
  font-family: "futuraMedium";
}
</style>
